// React Components Imports
import * as React from "react";

// Internal Imports
import { MainLayoutMainContainerTOC, MainLayoutCodeSample } from "@technologyone/t1-dxp-component-designsystem";
import Button from "@technologyone/t1-dxp-component-button/dist/button";
import InputBase from "@technologyone/t1-dxp-component-input-base";
import Icon, { FormattingSvgs } from "@technologyone/t1-dxp-component-icon";

// Props/State Interface
interface IntroductionTabProps {}

// Component Definition
class IntroductionTab extends React.Component<IntroductionTabProps> {

    // eslint-disable-next-line class-methods-use-this
    render() {
        return (
            <MainLayoutMainContainerTOC>
                <h2 id="WhatsIsADesignToken">What is a Design Token?</h2>
                <div className="ds-tokensPage-section">
                    <p>As defined by the <a target="blank" href="https://www.w3.org/community/design-tokens/">Design Tokens W3C Community Group</a>, design tokens are a methodology for expressing design decisions in a platform-agnostic way so that they can be shared across different disciplines, tools, and technologies. They help establish a common vocabulary across organisations.<br /><br />
                    Design decisions can be a color, a typeface, a border radius, an icon, a font size, a gradient or even an animation duration.</p>
                    <div className="ds-tokensPage-diagram">
                        <DiagramDesignToken
                            labelBold=""
                            label="Hardcoded value"
                            token="colorPrimaryDarker"
                            value="#3B4E58"
                            style="backgroundColor"
                        />
                        <DiagramDesignToken
                            labelBold="Palette token"
                            label="variable for hardcoded style"
                            token="displayNone"
                            value="--colorPrimaryDarker"
                            style="display"
                        />
                        <DiagramDesignToken
                            labelBold="Design token"
                            label="- the 'Decision'"
                            token="displayNone"
                            value="--colorButtonDefault"
                            style="display"
                        />
                        <div className="itemCntr componentItem">
                            <div className="label"><b></b> Component</div>
                            <div className="valueCntr">
                                <div className="bg">
                                    <Button buttonStyle="primary" labelText="Button" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <h2 id="Theming">Theming</h2>
                <div className="ds-tokensPage-section">
                    <p>The following shows an example of 3 different theme styles being applied to a button component. They all use the same design token but the main difference here is the hardcoded value which comes from the theme.</p>
                    <div className="ds-tokenPage-diagramsCntr">
                        <div className="ds-tokensPage-diagramCntr">
                            <h3>Employee DXP</h3>
                            <div className="ds-tokensPage-diagram">
                                <DiagramDesignToken
                                    labelBold=""
                                    label=""
                                    token="colorPrimaryDarker"
                                    value="#3B4E58"
                                    style="backgroundColor"
                                />
                                <DiagramDesignToken
                                    labelBold=""
                                    label=""
                                    token="displayNone"
                                    value="--colorPrimaryDarker"
                                    style="display"
                                />
                                <DiagramDesignToken
                                    labelBold=""
                                    label=""
                                    token="displayNone"
                                    value="--colorButtonDefault"
                                    style="display"
                                />
                                <div className="itemCntr componentItem">
                                    <div className="label"><b></b></div>
                                    <div className="valueCntr">
                                        <div className="bg">
                                            <Button buttonStyle="primary" labelText="Button" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ds-tokensPage-diagramCntr lgTheme">
                            <h3>LG DXP</h3>
                            <div className="ds-tokensPage-diagram">
                                <DiagramDesignToken
                                    labelBold=""
                                    label=""
                                    token="colorBlueDarker"
                                    value="#005A9C"
                                    style="backgroundColor"
                                />
                                <DiagramDesignToken
                                    labelBold=""
                                    label=""
                                    token="displayNone"
                                    value="--colorPrimaryDarker"
                                    style="display"
                                />
                                <DiagramDesignToken
                                    labelBold=""
                                    label=""
                                    token="displayNone"
                                    value="--colorButtonDefault"
                                    style="display"
                                />
                                <div className="itemCntr componentItem">
                                    <div className="label"><b></b></div>
                                    <div className="valueCntr">
                                        <div className="bg">
                                            <Button buttonStyle="primary" labelText="Button" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ds-tokensPage-diagramCntr customerTheme">
                            <h3>Customer Theme</h3>
                            <div className="ds-tokensPage-diagram">
                                <DiagramDesignToken
                                    labelBold=""
                                    label=""
                                    token="colorGreenDark"
                                    value="#006C3D"
                                    style="backgroundColor"
                                />
                                <DiagramDesignToken
                                    labelBold=""
                                    label=""
                                    token="displayNone"
                                    value="--colorPrimaryDarker"
                                    style="display"
                                />
                                <DiagramDesignToken
                                    labelBold=""
                                    label=""
                                    token="displayNone"
                                    value="--colorButtonDefault"
                                    style="display"
                                />
                                <div className="itemCntr componentItem">
                                    <div className="label"><b></b></div>
                                    <div className="valueCntr">
                                        <div className="bg">
                                            <Button buttonStyle="primary" labelText="Button" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <h2 id="StylingAttributesThatUseTokens">Styling attributes that use tokens</h2>
                <div className="ds-tokensPage-section">
                    <div className="ds-tokensPage-stylingAttributes">
                        <div className="itemCntr borderRadius">
                            <div className="example">
                                <Icon icon={FormattingSvgs.BorderRadius2Px} />
                            </div>
                            <div>border-radius</div>
                        </div>
                        <div className="itemCntr borderWidth">
                            <div className="example"></div>
                            <div>border-width</div>
                        </div>
                        <div className="itemCntr colours">
                            <div className="example"></div>
                            <div>colours</div>
                        </div>
                        <div className="itemCntr shadows">
                            <div className="example"></div>
                            <div>shadows</div>
                        </div>
                        <div className="itemCntr sizes">
                            <div className="example">
                                <Icon icon={FormattingSvgs.HierarchyParent} />
                            </div>
                            <div>sizes</div>
                        </div>
                        <div className="itemCntr spacing">
                            <div className="example">
                                <Icon icon={FormattingSvgs.ResizeFullWidth} />
                            </div>
                            <div>spacing</div>
                        </div>
                        <div className="itemCntr transitions">
                            <div className="example">
                                <Icon icon={FormattingSvgs.BorderRadius16Px} />
                            </div>
                            <div>transitions</div>
                        </div>
                        <div className="itemCntr typography">
                            <div className="example">
                                <Icon icon={FormattingSvgs.Text} />
                            </div>
                            <div>typography</div>
                        </div>
                    </div>
                </div>

                <h2 id="AnatomyOfAComponent">Anatomy of a Component</h2>
                <div className="ds-tokensPage-section">
                    <p>The following shows the breakdown of several styling token attributes that are used for the <a href="/#/component/@technologyone/t1-dxp-component-input-base/">input base component</a>.</p>

                    <div className="ds-section-scrollable">
                        <div className="ds-tokenPage-diagramsCntr anatomy">
                            <div className="example">
                                <InputBase
                                    labelText="Input Label"
                                    mandatory={true}
                                    width="large"
                                    errorText="error"
                                />
                            </div>


                            <div className="ds-tokensPage-diagramCntr">
                                <h3>border radius</h3>
                                <div className="ds-tokensPage-diagram">
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token=""
                                        value="2px"
                                        style="border"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--borderRadiusMedium"
                                        style="display"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--borderRadiusInput"
                                        style="display"
                                    />

                                </div>
                            </div>

                            <div className="ds-tokensPage-diagramCntr">
                                <h3>colour</h3>
                                <div className="ds-tokensPage-diagram">
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="colorNeutralDarker"
                                        value="#3B4E58"
                                        style="backgroundColor"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--colorNeutralDarker"
                                        style="display"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--colorTextDefault"
                                        style="display"
                                    />

                                </div>
                            </div>

                            <div className="ds-tokensPage-diagramCntr">
                                <h3>colour</h3>
                                <div className="ds-tokensPage-diagram">
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="colorYellowDark"
                                        value="FFB133"
                                        style="backgroundColor"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--colorYellowDark"
                                        style="display"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--colorMandatory"
                                        style="display"
                                    />

                                </div>
                            </div>

                            <div className="ds-tokensPage-diagramCntr">
                                <h3>border width</h3>
                                <div className="ds-tokensPage-diagram">
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="borderWidthSmall"
                                        value="1px"
                                        style="border"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--borderWidthSmall"
                                        style="display"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--inputBorderWidth"
                                        style="display"
                                    />

                                </div>
                            </div>

                            <div className="ds-tokensPage-diagramCntr">
                                <h3>colour</h3>
                                <div className="ds-tokensPage-diagram">
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="colorRedDark"
                                        value="#D8384B"
                                        style="backgroundColor"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--colorRedDark"
                                        style="display"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--colorError"
                                        style="display"
                                    />

                                </div>
                            </div>

                            <div className="ds-tokensPage-diagramCntr">
                                <h3>spacing</h3>
                                <div className="ds-tokensPage-diagram">
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="spacingTight-80"
                                        value="8px"
                                        style="padding"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--spacingTight80"
                                        style="display"
                                    />
                                    <DiagramDesignToken
                                        labelBold=""
                                        label=""
                                        token="displayNone"
                                        value="--paddingTextInputSides"
                                        style="display"
                                    />

                                </div>
                            </div>

                        </div>
                    </div>


                </div>


            </MainLayoutMainContainerTOC>
        );
    }
}

export default IntroductionTab;
export { IntroductionTabProps };

const DiagramDesignToken: React.FunctionComponent<{token: string, labelBold: string, label: string, value: string, style: keyof React.CSSProperties}> =
({ token, labelBold, label, value, style }) => (
    <div className="itemCntr">
        <div className="label"><b>{labelBold}</b> {label}</div>
        <div className="valueCntr">
            <div className="bg">
                <div className="swatchCntr">
                    <div className="swatch">
                        <div className="swatchInner" style={{ [style]: `var(--${token})` }}></div>
                    </div>
                </div>
                <div className="value">{value}</div>
            </div>
        </div>
    </div>
);
