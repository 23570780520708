// React Components Imports
import * as React from "react";

// Internal Imports
import { MainLayoutMainContainerTOC, MainLayoutCodeSample } from "@technologyone/t1-dxp-component-designsystem";

// Props/State Interface
interface PaletteTabProps {}

// Component Definition
class PaletteTab extends React.Component<PaletteTabProps> {
    render() {
        return (
            <MainLayoutMainContainerTOC>
                {/* <h2 id="colours">Colours</h2>
                <div className={`dxpKitTokensCntr colorTokens`}>
                    <div className="dxpKitTokensTableHeadings">
                        <div>Swatch</div>
                        <div>Value</div>
                        <div>Palette variable</div>
                    </div>
                    <div className="dxpKitTokensTableBody">
                        <div className="dxpKitTokensTableRow">
                            <div className="swatch colorTransparent">
                                <div className="swatchInner"></div>
                            </div>
                            <div className="value">
                                transparent
                            </div>
                            <div className="token">
                                --colorTransparent
                            </div>
                        </div>
                        <div className="dxpKitTokensTableRow">
                            <div className="swatch colorWhite">
                                <div className="swatchInner"></div>
                            </div>
                            <div className="value">
                                #FFFFFF
                            </div>
                            <div className="token">
                                --colorWhite
                            </div>
                        </div>
                        <div className="dxpKitTokensTableRow">
                            <div className="swatch colorBlack">
                                <div className="swatchInner"></div>
                            </div>
                            <div className="value">
                                #000000
                            </div>
                            <div className="token">
                                --colorBlack
                            </div>
                        </div>
                        <div className="dxpKitTokensTableRow">
                            <div className="swatch colorBlackOpac-01">
                                <div className="swatchInner"></div>
                            </div>
                            <div className="value">
                                rgba (0,0,0,0.01)
                            </div>
                            <div className="token">
                                --colorBlackOpac-01
                            </div>
                        </div>
                        <div className="dxpKitTokensTableRow">
                            <div className="swatch colorBlackOpac-10">
                                <div className="swatchInner"></div>
                            </div>
                            <div className="value">
                                rgba (0,0,0,0.10)
                            </div>
                            <div className="token">
                                --colorBlackOpac-10
                            </div>
                        </div>
                        <div className="dxpKitTokensTableRow">
                            <div className="swatch colorBlackOpac-20">
                                <div className="swatchInner"></div>
                            </div>
                            <div className="value">
                                rgba (0,0,0,0.20)
                            </div>
                            <div className="token">
                                --colorBlackOpac-20
                            </div>
                        </div>
                        <div className="dxpKitTokensTableRow">
                            <div className="swatch colorBlackOpac-30">
                                <div className="swatchInner"></div>
                            </div>
                            <div className="value">
                                rgba (0,0,0,0.30)
                            </div>
                            <div className="token">
                                --colorBlackOpac-30
                            </div>
                        </div>
                    </div>
                </div> */}



                <h2 id="BorderRadius">Border Radius</h2>
                <div className="ds-tokensPage-section borderRadiusTokens borderRadiusPaletteVariables">
                    <div className="ds-tokens-TableHeadings">
                        <div>{/* Example */}</div>
                        <div>Palette Variable(s)</div>
                        <div>Value</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <PaletteToken
                            token="borderRadiusSmall"
                            value="1px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusMedium"
                            value="2px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusLarge"
                            value="4px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusNone"
                            value="0"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusCircle"
                            value="50%"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSharp-10"
                            value="1px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSharp-20"
                            value="2px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSharp-40"
                            value="4px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSharp-60"
                            value="6px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSoft-10"
                            value="8px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSoft-30"
                            value="12px"
                            style="borderRadius"
                        />
                        <PaletteToken
                            token="borderRadiusSoft-50"
                            value="16px"
                            style="borderRadius"
                        />
                    </div>
                </div>

                <h2 id="BorderWidth">Border Width</h2>
                <div className="ds-tokensPage-section borderTokens borderPaletteVariables">
                    <div className="ds-tokens-TableHeadings">
                        <div>{/* Example */}</div>
                        <div>Palette Variable(s)</div>
                        <div>Value</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <PaletteToken
                            token="borderWidthNone"
                            value="0"
                            style="borderWidth"
                        />
                        <PaletteToken
                            token="borderWidthSmall"
                            value="1px"
                            style="borderWidth"
                        />
                        <PaletteToken
                            token="borderWidthMedium"
                            value="2px"
                            style="borderWidth"
                        />
                        <PaletteToken
                            token="borderWidthLarge"
                            value="4px"
                            style="borderWidth"
                        />

                    </div>
                </div>

                <h2 id="Colours">Colours</h2>
                <div className="ds-tokensPage-section coloursTokens colorPaletteVariables">
                    <div className="ds-tokens-TableHeadings">
                        <div>{/* Example */}</div>
                        <div>Palette Variable(s)</div>
                        <div>Value</div>

                    </div>
                    <div className="ds-tokens-TableBody">
                        <div className="coloursTBW">
                            <div className="ds-tokens-TableCategoryHeading">Transparent, Black & White Colours</div>
                            <PaletteToken
                                token="colorTransparent"
                                value="transparent"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlack"
                                value="#000000"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhite"
                                value="#FFFFFF"
                                style="backgroundColor"
                            />
                        </div>

                        <div className="coloursBlackOpac">
                            <div className="ds-tokens-TableCategoryHeading">Black Opacity Colours</div>
                            <PaletteToken
                                token="colorBlackOpac-01"
                                value="rgba(0,0,0,0,0.01)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-10"
                                value="rgba(0,0,0,0,0.1)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-15"
                                value="rgba(0,0,0,0,0.15)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-20"
                                value="rgba(0,0,0,0,0.2)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-25"
                                value="rgba(0,0,0,0,0.25)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-30"
                                value="rgba(0,0,0,0,0.3)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-40"
                                value="rgba(0,0,0,0,0.4)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorBlackOpac-50"
                                value="rgba(0,0,0,0,0.5)"
                                style="backgroundColor"
                            />

                        </div>

                        <div className="coloursWhiteOpac">
                            <div className="ds-tokens-TableCategoryHeading">White Opacity Colours</div>
                            <PaletteToken
                                token="colorWhiteOpac-05"
                                value="rgba(0,0,0,0,0.05)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-10"
                                value="rgba(0,0,0,0,0.1)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-15"
                                value="rgba(0,0,0,0,0.15)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-20"
                                value="rgba(0,0,0,0,0.2)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-30"
                                value="rgba(0,0,0,0,0.3)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-40"
                                value="rgba(0,0,0,0,0.4)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-50"
                                value="rgba(0,0,0,0,0.5)"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorWhiteOpac-80"
                                value="rgba(0,0,0,0,0.8)"
                                style="backgroundColor"
                            />

                        </div>

                        <div className="coloursPrimary">
                            <div className="ds-tokens-TableCategoryHeading">Primary Colours</div>
                            <PaletteToken
                                token="colorPrimaryDarkest"
                                value="#23323A"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryDarker"
                                value="#3B4E58"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryDark"
                                value="#5F717C"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimary"
                                value="#7E93A0"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLight"
                                value="#ADC2CD"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLighter"
                                value="#D0DFE7"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLighter-50"
                                value="#E7F1F4"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLightest"
                                value="#EEF5F7"
                                style="backgroundColor"
                            />
                            <PaletteToken
                                token="colorPrimaryLightest-50"
                                value="#F6FAFB"
                                style="backgroundColor"
                            />
                        </div>
                        <div className="coloursSecondary">
                            <div className="ds-tokens-TableCategoryHeading">Secondary Colours</div>
                            <PaletteToken
                                token="colorSecondary"
                                value="var(--colorPrimary)"
                                style="backgroundColor"
                            />
                        </div>

                    </div>
                </div>
            </MainLayoutMainContainerTOC>
        );
    }
}

export default PaletteTab;
export { PaletteTabProps };

const PaletteToken: React.FunctionComponent<{token: string, value: string, style: keyof React.CSSProperties}> =
({token, value, style}) => (
    <div className="ds-tokens-TableRow">
        <div className="swatchCntr">
            <div className={`swatch`}>
                <div className="swatchInner" style={{[style]: `var(--${token})`}}>
                    <div className="swatchInner2">
                        <div className="swatchInner3">
                            <div className="swatchInner4"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="token">
            <span>--</span>{token}
        </div>

        <div className="value">
            {value}
        </div>

    </div>
);
